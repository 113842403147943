import * as Sentry from "@sentry/gatsby";

import { BrowserTracing } from "@sentry/tracing";

//Only include monitoring in production
if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    sampleRate: 1,
    beforeSend(event) {
      // Modify the event here
      if (event.user) {
        // Don't send user's email address
        delete event.user.email;
      }
      return event;
    },
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          "digibin.wastequotesupermarket.co.uk",
          "api.wastequotesupermarket.co.uk",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1,
  });
}
